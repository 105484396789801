<template>
<div>
  <head-layout
    head-title="有限空间台账"
    :head-btn-options="headBtnOptions"
    @head-remove="handleDelete"
    @head-add="headAdd"
  ></head-layout>
  <grid-head-layout
    ref="gridHeadLayoutMy"
    :search-columns="searchColumns"
    @grid-head-search="onLoad"
    @grid-head-empty="onLoad"
  ></grid-head-layout>
  <grid-layout
    ref="gridLayOutMy"
    :table-options="tableOptions"
    :table-data="tableData"
    :table-loading="tableLoading"
    :grid-row-btn="gridRowBtn"
    @grid-edit="edit"
    @grid-romve="remove"
    @gird-handle-select-click="selectionChange"
  >
  </grid-layout>

  <common-dialog
    :dialogTitle="title"
    v-if="show"
    width="80%"
    @cancel="close"
    @confirm="submit">
    <el-form ref="dataForm" :rules="rules" :model="dataForm" label-width="150px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="有限空间名称:" prop="limitSpaceName">
            <el-input maxlength="100" v-model="dataForm.limitSpaceName" placeholder="请输入有限空间名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在位置:" prop="location">
            <el-input maxlength="100" v-model="dataForm.location" placeholder="请输入所在位置"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="原有介质名称:" prop="originalMediaName">
            <el-input maxlength="100" v-model="dataForm.originalMediaName" placeholder="请输入原有介质名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="主要危害因素:" prop="mainHazardFactors">
            <el-input maxlength="500" v-model="dataForm.mainHazardFactors" placeholder="请输入主要危害因素"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="危害后果:" prop="harmfulConsequences">
            <el-input maxlength="500" v-model="dataForm.harmfulConsequences" placeholder="请输入危害后果"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主要控制措施:" prop="mainControlMeasures">
            <el-input maxlength="500" v-model="dataForm.mainControlMeasures"
                      placeholder="请输入主要控制措施"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="责任人:" prop="personUser">
            <el-input v-model="dataForm.personUser" placeholder="请输入责任人">
              <el-button slot="append" icon="el-icon-search" @click="openUser()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="责任部门:" prop="personDept">
            <el-input v-model="dataForm.personDept" placeholder="请输入责任部门">
              <el-button slot="append" icon="el-icon-search" @click="openDeptBelow()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <avue-form
            :option="option"
            v-model="dataForm"
            :upload-preview="rowView"
            :upload-after="uploadAfter"
          ></avue-form>
        </el-col>
      </el-row>
    </el-form>
  </common-dialog>

<!--  <el-dialog :title="title" style="height: 80%" width="80%" append-to-body :visible.sync="show">-->
<!--      <el-form ref="dataForm" :rules="rules" :model="dataForm" label-width="150px">-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="有限空间名称:" prop="limitSpaceName">-->
<!--              <el-input maxlength="100" v-model="dataForm.limitSpaceName" placeholder="请输入有限空间名称"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="所在位置:" prop="location">-->
<!--              <el-input maxlength="100" v-model="dataForm.location" placeholder="请输入所在位置"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="原有介质名称:" prop="originalMediaName">-->
<!--              <el-input maxlength="100" v-model="dataForm.originalMediaName" placeholder="请输入原有介质名称"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="主要危害因素:" prop="mainHazardFactors">-->
<!--              <el-input maxlength="500" v-model="dataForm.mainHazardFactors" placeholder="请输入主要危害因素"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="危害后果:" prop="harmfulConsequences">-->
<!--              <el-input maxlength="500" v-model="dataForm.harmfulConsequences" placeholder="请输入危害后果"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="主要控制措施:" prop="mainControlMeasures">-->
<!--              <el-input maxlength="500" v-model="dataForm.mainControlMeasures" placeholder="请输入主要控制措施"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="责任人:" prop="personUser">-->
<!--              <el-input v-model="dataForm.personUser" placeholder="请输入责任人">-->
<!--                <el-button slot="append" icon="el-icon-search" @click="openUser()"></el-button>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="责任部门:" prop="personDept">-->
<!--              <el-input v-model="dataForm.personDept" placeholder="请输入责任部门">-->
<!--                <el-button slot="append" icon="el-icon-search" @click="openDeptBelow()"></el-button>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <avue-form-->
<!--              :option="option"-->
<!--              v-model="dataForm"-->
<!--              :upload-preview="rowView"-->
<!--              :upload-after="uploadAfter"-->
<!--              :upload-delete="uploadDelete"-->
<!--            ></avue-form>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="close">{{ $t('cip.cmn.btn.celBtn') }}</el-button>-->
<!--        <el-button type="primary" @click="submit">{{ $t('cip.cmn.btn.defBtn') }}</el-button>-->
<!--      </span>-->
<!--  </el-dialog>-->

  <el-dialog
    title="部门选择"
    :visible.sync="deptBelowShow"
    width="1000px">
    <currentDepartmentBelow @getDeptBelow="getDeptBelow"></currentDepartmentBelow>
  </el-dialog>
  <!--当前部门及一下弹窗-->
  <el-dialog
    title="人员选择"
    :visible.sync="userShow"
    top="9vh"
    width="70%">
    <UserDetpDialog @select-data="getUser"></UserDetpDialog>
  </el-dialog>
</div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import currentDepartmentBelow from "@/views/components/currentDepartmentBelow";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import {Remove,Save,Detail,List} from "@/api/wtLimitSpace/limitSpaceLedger"
import SingleFIleUpload from "@/components/file-upload/SingleFIleUpload";
import website from "@/config/website";
import {putFile} from "@/api/system/user";
import {nowTrainDeleteImage, nowTrainSaveImage} from "@/api/onTheJobTraining/training";
import CommonDialog from "@/components/CommonDialog";
import {mapGetters} from "vuex";
export default {
  name: "index",
  components: { UserDetpDialog,currentDepartmentBelow,SingleFIleUpload,GridLayout,CommonDialog },
  data(){
    return {
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 140,
        column: [
          {
            label: "警示标识设置情况",
            prop: "settingWarningSigns",
            listType: "picture-card",
            type: "upload",
            fileType: "img",
            span: 24,
            limit: 9,
            propsHttp: {
              res: "data",
              url: "link",
            },
            tip: '最大上传9张图片',
            action: "api/sinoma-resource/oss/endpoint/put-file",
          },
        ],
      },
      ids:'',
      show: false,
      dataForm: {
        personUser: '',
        personDept: '',
        settingWarningSignList:null
      },
      title:'新增有限空间台账',
      userShow: false,
      deptBelowShow: false,
      headBtnOptions:[
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          btnOptType: "add",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-remove",
          btnOptType: "remove",
        },
      ],
      searchColumns:[{
        prop: "limitSpaceName",
        type: "input",
        span: 3,
        placeholder: "请输入有限空间名称",
      },],
      tableOptions:{
        linklabel: "code",
        selectable: (row, index) => {
          return true
        },
        column: [
          {
            label: "有限空间名称",
            prop: "limitSpaceName",
            align: "left",
            overHidden: true,
          },
          {
            label: "所在位置",
            align: "center",
            prop: "location",
            overHidden: true,
          },
          {
            label: "原有介质名称",
            prop: "originalMediaName",
            align: "center",
            overHidden: true,
          },
          {
            label: "主要危害因素",
            prop: "mainHazardFactors",
            align: "center",
            overHidden: true,
          },
          {
            label: "危害后果",
            prop: "harmfulConsequences",
            align: "center",
            overHidden: true,
          },
          {
            label: "主要控制措施",
            prop: "mainControlMeasures",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任人",
            prop: "personUser",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任部门",
            prop: "personDept",
            align: "center",
            overHidden: true,
          },
        ],
      },
      gridRowBtn:[
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          type: "text",
          icon: ""
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          type: "text",
          icon: ""
        }
      ],
      tableData:[],
      tableLoading:false,
      dataTotal:0,
      rules: {
        limitSpaceName: [{required: true, message: '请输入有限空间名称', trigger: 'blur'}],
        location: [{required: true, message: '请输入所在位置', trigger: 'blur'}],
        originalMediaName: [{required: true, message: '请输入原有介质名称', trigger: 'blur'}],
        mainHazardFactors: [{required: true, message: '请输入主要危害因素', trigger: 'blur'}],
        harmfulConsequences: [{required: true, message: '请输入危害后果', trigger: 'blur'}],
        mainControlMeasures: [{required: true, message: '请输入主要控制措施', trigger: 'blur'}],
        personUser: [{required: true, message: '请选择责任人', trigger: ['blur', 'change']}],
        personDept: [{required: true, message: '请选择责任部门', trigger: ['blur', 'change']}],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    List().then(res =>{
      this.tableData = res.data.data
    })
  },
  methods:{
    remove(row){
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        Remove(row.id).then(() => {
          this.onLoad();
          this.$message.success('操作成功！')
        })
      });
    },
    edit(row){
      this.dataForm = row
      this.show = true
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading(true)
          if (Array.isArray(this.dataForm.settingWarningSigns)) {
            this.dataForm.settingWarningSigns = this.dataForm.settingWarningSigns.map(item => {
              return item.value
            }).join(',')
          }
          Save(this.dataForm).then(res => {
            if (res.data.code == 200) {
              this.dataForm = {}
              this.onLoad()
              this.$message.success("保存成功")
              this.show = false
            }
          }).finally(() => {
            this.$loading().close()
          })
        }
      })
    },
    close() {
      this.show = false
      this.dataForm = {
        personUser: '',
        personDept: '',
        settingWarningSignList: null
      }
    },
    openUser() {
      this.userShow = true;
    },
    openDeptBelow() {
      this.deptBelowShow = true;
    },
    getDeptBelow(data) {
      this.deptBelowShow = false;
      this.dataForm.personDept = data.deptName;
      this.dataForm.personDeptId = data.id;
    },
    getUser(data,deptId,deptName) {
      this.userShow = false;
      this.dataForm.personUserId = data.id;
      this.dataForm.personUser = data.realName;
      this.dataForm.personDeptId = deptId;
      this.dataForm.personDept = deptName;
    },
    handleDelete(){
      this.$confirm("确定删除所选数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        Remove(this.ids).then(() => {
          this.onLoad();
          this.$message.success('操作成功！')
        })
      });
    },
    headAdd(){
      this.show = true
    },
    onLoad(){
      let data = this.$refs.gridHeadLayoutMy.searchForm.limitSpaceName
      console.log(this.$refs.gridHeadLayoutMy)
      if(data === undefined){
        data = ''
      }
      List({LimitSpaceName: data, person_dept_id: this.userInfo.dept_id}).then(res =>{
        this.tableData = res.data.data
      })
    },
    selectionChange(list){
      if (list.length > 0)this.ids = list.map(e=>e.id).join(',')
    },
    uploadAfter(res,done){
      console.log(res,"===111===")
      this.$message.success("上传成功");
      done();
    },
    rowView(file, column, done) {
      let url = this.map.get(file.name);
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" + encodeURIComponent(Base64.encode(url));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    uploadDelete(file, column) {
      let boolean = file.name.indexOf("upload") != -1;
      if (!boolean) {
        nowTrainDeleteImage(file.name).then((res) => {
          this.$message.success("删除成功");
        });
      }
    },
  }
}
</script>

<style scoped lang="less">


</style>
